<template>
  <b-card title="Ongoing Delivery Picking Process">
    
    <b-row> 
      <!-- Modal for showing Job Order Details When Clicking Approve-->
      <b-col cols="12">
        <b-row style="margin-bottom:15px;">
          <b-col cols="4" class="hide-on-mobile">
          </b-col>
          <b-col cols="4" class="hide-on-mobile">
          </b-col>
          <b-col cols="4" class="hide-on-mobile">
            <div style="display: flex; align-items: center;">
              <b-form-input
                v-model="searchBox"
                type="search"
                placeholder="Search By SO number"
                style="margin-right: 10px;"
              />
              <b-button @click="doFiltering" variant="primary">Search</b-button>
            </div>
          </b-col>

          <b-col cols="4" class="hide-on-desktop">
            <div>
              <b-form-input
                v-model="searchBox"
                type="search"
                placeholder="SO Number"
                style="margin-right: 10px;"
              />
            </div>
          </b-col>
          <b-col cols="4" class="hide-on-desktop">
            <b-button @click="doFiltering" variant="primary">Search</b-button>
          </b-col>
          <b-col cols="4" class="hide-on-desktop">
          </b-col>

          <b-modal
          v-model="promptDetail"
          :title="titleModal"
          id="myModal"
          size="xl"
          ok-only
          responsive
          no-close-on-backdrop
          no-close-on-esc
          :hide-header-close="closeModal"
          >
            <b-row>
              <b-col cols="12" style="margin-top:10px">
                <b-row>
                  <b-col cols="4"><strong>SO Number</strong></b-col>
                  <b-col>: {{ form? form.delivery.orderNumber : ''}}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4"><strong>Order Date</strong></b-col>
                  <b-col>: {{ form? form.delivery.createdAt : ''}}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4"><strong>Delivery Address 1</strong></b-col>
                  <b-col>: {{ form? form.delivery.deliveryAddressOne : ''}}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4"><strong>Delivery Address 2</strong></b-col>
                  <b-col>: {{ form? form.delivery.deliveryAddressTwo : ''}}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4"><strong>Timer</strong></b-col>
                  <b-col>: {{ (this.startTimer==null)?"":totalTimer }}</b-col>
                </b-row>
                <br>
                <br>

                <b-table
                  :items="detail"
                  :fields="fieldPicklist"
                  responsive="xl"
                  hover
                  head-variant="dark"
                  outlined
                  small
                >
                  <template #cell(no)="row">
                    {{ row.index + 1 }}
                  </template>
                </b-table>
                <div>
                  <b-button
                    variant="success"
                    size="sm"
                    block  
                    :disabled="disablePick"
                    @click="startDelivery"
                  >
                    Delivery Picking Start
                  </b-button>
                  <b-button
                    variant="warning"
                    size="sm"
                    block
                    :disabled="!deliveryStarted"
                    @click="stopDelivery"
                  >
                    Delivery Picking End
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <template #modal-footer>
              <div class="w-100"></div>
            </template>
          </b-modal>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div style="overflow-x: visible;">
              <b-table
                :filter="search"
                hover
                responsive
                small
                head-variant="dark"
                outlined
                :items="delivery"
                :fields="headers"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template #cell(jobOrderID)="row">
                  {{ row.item.id }}
                </template>
                <template #cell(orderNumber)="row">
                  {{ row.item.orderNumber }}
                </template>
                <template #cell(item)="row">
                  {{ simplyfiedItems(row.item.item) }}
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    :variant="buttonColor(row.item.orderNumber).variantColor"
                    size="sm"
                    style="margin-right:10px"
                    @click="
                      detail(row.item);
                    "
                    >{{ buttonColor(row.item.orderNumber).variantText }}</b-button
                  >
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="pt-1">
            <b-form-group
              label="Data Per Page"
              label-for="per-page-select"
              label-cols-md="0"
              label-align-sm="left"
              label-size="md"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                @input="queryDelivery()"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="pt-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              first-number
              last-number
              class="float-right"
              aria-controls="user-table"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  dateFormat,
  userAccess,
  userAccessManufacture,
  viewAccess,
  sleep
} from "@/utils/utils.js";

import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      form: {
        orderNumber: [],
        data: [],
        delivery: {},
      },
      disablePick: false,
      deliveryStarted: false,
      stackedStatus: "md",
      showSpinner: false,
      headers: [
        {
          key: "orderNumber",
          label: "SO Number",
          filterable: true,
          sortable: true,
        },
        {
          key: "picklistNo",
          label: "Picklist Number",
          filterable: true,
          sortable: true,
        },
        {
          key: "totalPicklist",
          label: "Total Picklist",
          filterable: true,
          sortable: true,
        },
        {
          key: "delivered",
          label: "Picked",
          filterable: true,
          sortable: true,
        },
        {
          key: "canBeProcess",
          label: "Ready for Picking",
          filterable: true,
          sortable: true,
        },
        // {
        //   key: "onGoing",
        //   label: "Ongoing Picklist",
        //   filterable: true,
        //   sortable: true,
        // },
        {
          key: "companyName",
          label: "Company Name",
          filterable: true,
          sortable: true,
        },
        {
          key: "deliveryAddressOne",
          label: "Delivery address",
          filterable: true,
          sortable: true,
        },
        {
          key: "remark",
          label: "Remark",
          filterable: true,
          sortable: true,
        },
        { key: "actions", label: "Actions" },
      ],
      fieldPicklist: [
        {
          key: "picklistNumber",
          label: "Pick List Number",
          filterable: true,
          sortable: false,
        },
        {
          key: "lineItem",
          label: "Line Item",
          filterable: true,
          sortable: false,
        },
        {
          key: "lineDescription",
          label: "Line Description",
          filterable: true,
          sortable: false,
        },
        {
            key: "fromBatch",
            label: "Drum Number",
            filterable: true,
            sortable: false,
          },
        {
          key: "pickListType",
          label: "Pick List Type",
          filterable: true,
          sortable: false,
        },
        {
          key: "quantity",
          label: "Quantity",
          filterable: true,
          sortable: false,
        },
        {
            key: "unit",
            label: "Unit",
            filterable: true,
            sortable: false,
          },
          {
            key: "binLocation",
            label: "Bin Location",
            filterable: true,
            sortable: false,
          },
        {
          key: "props.statusNo",
          label: "Status",
          filterable: true,
          sortable: false,
        },
        
        {
          key: "cableLengthInfo",
          label: "Cable Length",
          filterable: true,
          sortable: false,
        },
        {
          key: "cableWeight",
          label: "Cable Weight",
          filterable: true,
          sortable: false,
        },
        {
          key: "machineName",
          label: "Machine",
          filterable: true,
          sortable: false,
        },
        {
          key: "user",
          label: "Cutter",
          filterable: true,
          sortable: false,
        },
      ],
      //sort direction list
      directions: [
        { key: false, label: "Asc", sortable: true },
        { key: true, label: "Desc", sortable: true },
      ],
      jobContext: null,
      search: null,
      pageOptions: [5, 10, 20, 100],
      sortBy: "",
      sortDesc: false,
      buttonState: false,
      currentPage: 1,
      perPage: 5,
      promptDetail: false,
      dataApproval: "",
      titleModal: "",
      closeModal: true,
      totalRows: 1,
      searchBox: '',
      orderNumber: '',
      dataPicklist: [],
      intervalFetch : null,

      //notify
        notifyModal:false,
        notifyData:{},
        startTimer:null,
        totalTimer:"",
    };
  },
  mounted() {
    this.queryDelivery();

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageOngoingDeliveryManufacture")) {
      this.perPage = this.$session.get("perPageOngoingDeliveryManufacture");
    }
    if (this.$session.has("sortByOngoingDeliveryManufacture")) {
      this.sortBy = this.$session.get("sortByOngoingDeliveryManufacture");
    }
    if (this.$session.has("sortDescOngoingDeliveryManufacture")) {
      this.sortDesc = this.$session.get("sortDescOngoingDeliveryManufacture");
    }
  },
  destroyed(){
    clearInterval(this.intervalFetch)
    this.intervalFetch = null
  },
  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageOngoingDeliveryManufacture", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByOngoingDeliveryManufacture", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescOngoingDeliveryManufacture", sortDescNew);
    },
  },

  methods: {
    ...mapActions({
      getDeliveryOrders: "delivery/getDelivery",
    }),
    async timerRunStart(){
      var currentTime = new Date().getTime();
      var startTime = new Date(this.startTimer).getTime();
      var elapsedTime = currentTime - startTime;
      const hours = Math.floor(elapsedTime / (1000 * 60 * 60));
      const minutes = Math.floor(
        (elapsedTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);
      this.totalTimer= `${hours
      .toString()
      .padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      await sleep(1000)
      if(this.startTimer!=null){
        this.timerRunStart()
      }
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
    },

    queryDelivery(){
      this.getDeliveryOrders({
        entry : this.perPage,
        page : this.currentPage,
        isCompleted : false,
        orderNumber : this.orderNumber,
      }).then((data) => {
        this.dataPicklist = data.dataPicklist
        this.processMetadata(data.metadata)
      }).catch((e) => {
        this.$bvToast.toast("Load data failed, please reload the page.", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        console.log(e);
      });
    },
    
    onPageChange(page) {
      this.currentPage = page;
      this.queryDelivery();
    },
    buttonColor(orderNumber) {
      var data = this.dataPicklist.filter(
        (x) => x.orderNumber == orderNumber && x.pickListType != "R"
      );
      let color = ''
      let text = ''
      let title = ''
      let hideX = true

      // Check if all elements have statusNo equal to 55
      let hasStatusNo55OrSPickList = data.some((element) => {
        return (element.props.statusNo === 55 && ((element.props.DPS != undefined && element.props.DPE == undefined)||(element.props.DPS == undefined && element.props.DPE == undefined))) || (element.pickListType === 'S' && (element.props.DPS != undefined && element.props.DPE == undefined)) ||  (element.pickListType === 'S' && (element.props.DPS == undefined && element.props.DPE == undefined));
      });

      if (hasStatusNo55OrSPickList) {
          color = 'success';
          text = 'Process';
          title = 'Delivery Process'
          if (!this.deliveryStarted) {
            hideX = false
          }
          else {
            hideX = true
          }
      } else {
        color = 'secondary';
        text = 'View';
        title = 'Delivery Process'
        hideX = false
      }
      let element = {
        variantColor : color,
        variantText : text,
        variantTitle : title,
        variantHide : hideX
      }
      return element
    },

    detail(item, index, event) {
      this.$router.push({name:'dlivery-picking-process', params:{sonumber:item.orderNumber}})
      // if (item.orderNumber != undefined) {
      //   this.form.delivery = item;
      //   this.titleModal = this.buttonColor(item.orderNumber).variantTitle
      //   this.closeModal = this.buttonColor(item.orderNumber).variantHide
      //   this.form.delivery.createdAt = dateFormat(item.createdAt)
      // }
      
      // var data = this.dataPicklist
      //   .filter((el) => {
      //     return el.orderNumber == this.form.delivery.orderNumber && el.pickListType != 'R';
      //   })
      //   .map((el) => {
      //     el.picklistNumber = el.picklistNo + "_" + el.picklistLine;
      //     this.form.orderNumber.push(el);

      //      // Tambahkan machineName berdasarkan syarat
      //       if (el.pickListType === 'C') {
      //         el.machineName = el.props.machineName || 'Not Set Up';
      //         el.user = el.props.user || 'Not Set Up';

      //       }

      //     return el;
      //   })
      // this.form.data = data;

      // var hasStatusNot55 = this.form.data.some(
      //   (el) => el.props.statusNo != "55" && el.pickListType != "S" 
      // );
      // this.form.data.map((el) => {
      //   if (el.props.DPS != undefined || el.props.DPE != undefined) {
      //     hasStatusNot55 = true;
      //   }
      // });
      // this.disablePick = hasStatusNot55;

      // var hasCode = false;
      // this.form.data.map((el) => {
      //   if (el.props.DPS != undefined) {
      //     hasCode = true;
      //   }
      //   if (el.props.DPE != undefined) {
      //     hasCode = false;
      //   }
      // });

      // this.deliveryStarted = hasCode;
      // console.log('this.disablePick', this.disablePick);
      // console.log('this.deliveryStarted', this.deliveryStarted);
      // if(this.disablePick == true && this.deliveryStarted == true ){
      //   console.log('masuk');
      //   this.startTimer= new Date(this.form.data[0].props.DPS);
      //   this.timerRunStart();
      // }

      // return data
    },
    doFiltering(){
      this.orderNumber = this.searchBox
      this.queryDelivery()
    },
    simplyfiedItems(items) {
      return items.reduce((prev, next) => {
        if (next === null || next === undefined) {
          return prev;
        }

        let humanName = next.detail || next.name;

        return [...prev, humanName];
      }, []);
    },
    startDelivery() {
      this.disablePick = true;
      this.deliveryStarted = true;
      this.closeModal = true

      let arrayId = this.form.data.map(el => el.id)
      this.$store
        .dispatch("picklist/pickListTimestampMany", {
          id: arrayId,
          timestamp: "DPS",
        })
        .then((x) => {
          this.startTimer = new Date();
          this.timerRunStart();
          this.$bvToast.toast("Delivery Started ", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.queryDelivery()
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
    },
    stopDelivery() {
      this.deliveryStarted = false;
      this.promptDetail = false;
      this.closeModal = false

      let arrayId = this.form.data.map(el => el.id)
      this.$store
        .dispatch("picklist/pickListTimestampMany", {
          id: arrayId,
          timestamp: "DPE",
        })
        .then((x) => {
          this.startTimer=null;
          this.$bvToast.toast("Delivery Completed ", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.queryDelivery()
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
    },
  },
  computed: {
    delivery() {
      let delivery = this.$store.getters["delivery/getDelivery"].map(el => {
        var newOrderDate = dateFormat(el.orderDate).split(",")
        var picklist = this.dataPicklist.filter(item => item.orderNumber == el.orderNumber)
        var canBeProcess = picklist.filter(item => !(item.pickListType == "C" && item.props.statusNo != 55) && item.props.DPS == undefined)
        var onGoing = picklist.filter(item => item.props.DPS != undefined && item.props.DPE == undefined)
        var delivered = picklist.filter(item => item.props.DPS != undefined && item.props.DPE != undefined)
        return {
          ...el,
          orderDate : newOrderDate[0] + newOrderDate[1].replace(/\./g, ':'),
          totalPicklist : picklist.length,
          canBeProcess : canBeProcess.length,
          onGoing : onGoing.length,
          delivered : delivered.length
        }
      })
      return delivery;
    },
    permission() {
      return userAccess("ongoing delivery picking");
    },
    rows() {
      return this.delivery.length;
    },
  },
};
</script>

<style>
#myModal .modal-dialog {
  max-width: 90%; /* Atur lebar maksimum sesuai kebutuhan Anda */
  width: 100%;
  margin: auto;
}

@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #0077b3;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: 20px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
}
</style>
